import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import React from "react";

import { navigateTo } from "../../router/browserHistory";
import { selectProjectRoute } from "../../router/router.selectors";

import styles from "./DesignLayout.module.scss";
import { ProjectPreview } from "./ProjectPreview";
import { projectsData } from "./projectsData";

export const DesignLayout = () => {
    const onSelectProject = (id: string) => () => {
        navigateTo(selectProjectRoute(id));
    };

    const projects = projectsData.map((project, index) => {
        const { backInfo, id, label, preview } = project;

        if (id === "other") {
            return (
                <span key={"project_" + index} className={styles.otherProjectsBlock}>
                    <Button className={styles.otherProjectsButton} variant="outlined" onClick={onSelectProject(id)}>
                        Other projects
                    </Button>
                </span>
            );
        }

        return (
            <ProjectPreview
                key={"project_" + index}
                back={backInfo}
                index={index}
                label={label}
                preview={preview}
                onSelect={onSelectProject(id)}
            />
        );
    });

    return (
        <div className={styles.container}>
            <div className={styles.description}>
                <div>
                    Р’ СЃРІРѕРµР№ РїСЂР°РєС‚РёРєРµ РєРѕРЅС†РµРїС‚СѓР°Р»СЊРЅРѕРіРѕ РґРёР·Р°Р№РЅР° РёРЅС‚РµСЂСЊРµСЂРѕРІ СЏ СЂР°Р±РѕС‚Р°СЋ СЃ РїСЂРѕСЃС‚СЂР°РЅСЃС‚РІРѕРј РєР°Рє СЃ РјРЅРѕРіРѕСЃР»РѕР№РЅС‹Рј
                    С‚РµРєСЃС‚РѕРј, РіРґРµ РєР°Р¶РґР°СЏ РґРµС‚Р°Р»СЊ СЃС‚Р°РЅРѕРІРёС‚СЃСЏ РЅРѕСЃРёС‚РµР»РµРј СЃРјС‹СЃР»РѕРІ, РјРµС‚Р°С„РѕСЂ Рё Р°Р»Р»СЋР·РёР№.
                </div>
                <div>
                    Р”Р»СЏ РєР°Р¶РґРѕРіРѕ РїСЂРѕРµРєС‚Р° СЏ РІС‹Р±РёСЂР°СЋ РµРґРёРЅСѓСЋ С‚РµРјСѓ вЂ” Р±СѓРґСЊ С‚Рѕ СЃР°РєСЂР°Р»СЊРЅС‹Рµ РЅР°СЂСЂР°С‚РёРІС‹, РІРёР·СѓР°Р»СЊРЅР°СЏ РґСЂР°РјР°С‚СѓСЂРіРёСЏ
                    Р‘СЂРµР№РіРµР»СЏ РёР»Рё СЌСЃС‚РµС‚РёРєР° Р’РµРЅСЃРєРѕРіРѕ РјРѕРґРµСЂРЅР° вЂ” Рё РІС‹СЃС‚СЂР°РёРІР°СЋ РёРЅС‚РµСЂСЊРµСЂ РєР°Рє СЃС†РµРЅРѕРіСЂР°С„РёСЋ, РІ РєРѕС‚РѕСЂРѕР№ РјР°С‚РµСЂРёР°Р»,
                    РїСЂРµРґРјРµС‚ Рё С†РІРµС‚ СЂР°Р±РѕС‚Р°СЋС‚ РЅР° СЂР°СЃРєСЂС‹С‚РёРµ Р·Р°Р»РѕР¶РµРЅРЅРѕР№ РёРґРµРё.
                </div>
                <div>
                    РњРµРЅСЏ РёРЅС‚РµСЂРµСЃСѓРµС‚ РЅРµ Р±СѓРєРІР°Р»СЊРЅРѕРµ С†РёС‚РёСЂРѕРІР°РЅРёРµ, Р° СЃРѕР·РґР°РЅРёРµ Р°С‚РјРѕСЃС„РµСЂС‹, РІ РєРѕС‚РѕСЂРѕР№ СЃРёРјРІРѕР»С‹, РѕС‚С‚РµРЅРєРё Рё
                    СЃС‚СЂСѓРєС‚СѓСЂРЅС‹Рµ СЂРµС€РµРЅРёСЏ СЂРµР·РѕРЅРёСЂСѓСЋС‚ СЃ РєСѓР»СЊС‚СѓСЂРЅС‹РјРё РєРѕРґР°РјРё РІС‹Р±СЂР°РЅРЅРѕР№ С‚РµРјС‹, РІС‹Р·С‹РІР°СЏ Сѓ Р·СЂРёС‚РµР»СЏ РЅРµРѕС‡РµРІРёРґРЅС‹Рµ,
                    РЅРѕ РіР»СѓР±РёРЅРЅС‹Рµ Р°СЃСЃРѕС†РёР°С†РёРё.
                </div>
                <p>
                    РЇ РёС‰Сѓ РІ РёРЅС‚РµСЂСЊРµСЂРµ РјРѕРјРµРЅС‚С‹ В«СЃРјС‹СЃР»РѕРѕР±СЂР°Р·СѓСЋС‰РµРіРѕ РЅР°РїСЂСЏР¶РµРЅРёСЏВ», РєРѕРіРґР° РїСЂРѕСЃС‚СЂР°РЅСЃС‚РІРѕ СЃС‚Р°РЅРѕРІРёС‚СЃСЏ РЅРµ РїСЂРѕСЃС‚Рѕ
                    РѕР±РѕР»РѕС‡РєРѕР№, РЅРѕ Р°РєС‚РёРІРЅРѕР№ СЃСЂРµРґРѕР№, РїСЂРѕРІРѕС†РёСЂСѓСЋС‰РµР№ РґРёР°Р»РѕРі, С„РѕСЂРјРёСЂСѓСЋС‰РµР№ СЌРјРѕС†РёСЋ, РїСЂРµРІСЂР°С‰Р°СЋС‰РµР№ РїСЂРёСЃСѓС‚СЃС‚РІРёРµ РІ
                    СЃРѕР±С‹С‚РёРµ! Рђ СЃСЂРµРґР° СЂР°Р±РѕС‚Р°РµС‚ РЅР° СѓСЂРѕРІРЅРµ С…СѓРґРѕР¶РµСЃС‚РІРµРЅРЅРѕРіРѕ РІС‹СЃРєР°Р·С‹РІР°РЅРёСЏ.
                </p>
                <p>
                    Р’ РјРѕРµРј РїРѕРЅРёРјР°РЅРёРё, РёРЅС‚РµСЂСЊРµСЂ вЂ” СЌС‚Рѕ РјРµРґРёСѓРј, СЃРїРѕСЃРѕР±РЅС‹Р№ С‚СЂР°РЅСЃР»РёСЂРѕРІР°С‚СЊ РЅРµ РїСЂРѕСЃС‚Рѕ РІРёР·СѓР°Р»СЊРЅС‹Рµ РѕР±СЂР°Р·С‹, РЅРѕ
                    РјРЅРѕРіРѕСЃР»РѕР№РЅС‹Рµ СЃРјС‹СЃР»РѕРІС‹Рµ СЃС‚СЂСѓРєС‚СѓСЂС‹, РІ РєРѕС‚РѕСЂС‹С… С„РѕСЂРјР°, С‚РµРєСЃС‚СѓСЂР° Рё СЃРІРµС‚ СЂР°Р±РѕС‚Р°СЋС‚ РєР°Рє СЏР·С‹РєРѕРІС‹Рµ РµРґРёРЅРёС†С‹.
                    РџСЂРѕСЃС‚СЂР°РЅСЃС‚РІРѕ СЃС‚Р°РЅРѕРІРёС‚СЃСЏ РјРµСЃС‚РѕРј РґРёР°Р»РѕРіР° РјРµР¶РґСѓ СЌРїРѕС…Р°РјРё, РєСѓР»СЊС‚СѓСЂРЅС‹РјРё РєРѕРґР°РјРё Рё Р·СЂРёС‚РµР»СЊСЃРєРёРј РІРѕСЃРїСЂРёСЏС‚РёРµРј,
                    Р° РґРёР·Р°Р№РЅ РїСЂРµРІСЂР°С‰Р°РµС‚СЃСЏ РІ РёРЅСЃС‚СЂСѓРјРµРЅС‚ РїРµСЂРµРѕСЃРјС‹СЃР»РµРЅРёСЏ РёСЃС‚РѕСЂРёС‡РµСЃРєРѕРіРѕ Рё С„РёР»РѕСЃРѕС„СЃРєРѕРіРѕ РєРѕРЅС‚РµРєСЃС‚Р°.
                </p>
                <p>
                    РЇ СЂР°Р±РѕС‚Р°СЋ РІ СЌСЃС‚РµС‚РёРєРµ, РєРѕС‚РѕСЂР°СЏ Р±Р°Р»Р°РЅСЃРёСЂСѓРµС‚ РјРµР¶РґСѓ РјРёРЅРёРјР°Р»РёР·РјРѕРј, СЃРєСѓР»СЊРїС‚СѓСЂРЅРѕР№ Р°СЂС…РёС‚РµРєС‚СѓСЂРЅРѕСЃС‚СЊСЋ Рё
                    СЌРјРѕС†РёРѕРЅР°Р»СЊРЅРѕР№ С‚Р°РєС‚РёР»СЊРЅРѕСЃС‚СЊСЋ РјР°С‚РµСЂРёР°Р»РѕРІ. РњРѕРё РїСЂРѕРµРєС‚С‹ С‡Р°СЃС‚Рѕ СЃС‚СЂРѕСЏС‚СЃСЏ РЅР° РґРёР°Р»РѕРіРµ С‚РµРєСЃС‚СѓСЂ, С‡РёСЃС‚РѕС‚Рµ
                    РіРµРѕРјРµС‚СЂРёРё Рё РЅРµРѕР¶РёРґР°РЅРЅС‹С… СЃРјС‹СЃР»РѕРІС‹С… РєРѕРЅС‚СЂР°СЃС‚Р°С….
                </p>
                <div>РљР»СЋС‡РµРІС‹Рµ С‡РµСЂС‚С‹ РјРѕРµРіРѕ СЃС‚РёР»СЏ:</div>
                <li>
                    <b>РњР°С‚РµСЂРёР°Р»СЊРЅР°СЏ СЌРєСЃРїСЂРµСЃСЃРёСЏ</b> вЂ“ РёСЃРїРѕР»СЊР·РѕРІР°РЅРёРµ РіСЂСѓР±С‹С…, С‡РµСЃС‚РЅС‹С… РїРѕРІРµСЂС…РЅРѕСЃС‚РµР№ (Р±РµС‚РѕРЅ, РєР°РјРµРЅСЊ, РґРµСЂРµРІРѕ,
                    РјРµС‚Р°Р»Р») СЃ СЃРёР»СЊРЅС‹Рј Р°РєС†РµРЅС‚РѕРј РЅР° С‚Р°РєС‚РёР»СЊРЅРѕСЃС‚СЊ.
                </li>
                <li>
                    <b>РџСЂРѕСЃС‚СЂР°РЅСЃС‚РІРµРЅРЅР°СЏ РґСЂР°РјР°С‚СѓСЂРіРёСЏ</b> вЂ“ Р»Р°РєРѕРЅРёС‡РЅС‹Рµ С„РѕСЂРјС‹ Рё РїСЂРµРґРјРµС‚С‹ РјРµР±РµР»Рё, РєРѕС‚РѕСЂС‹Рµ СЃРѕР·РґР°СЋС‚ Р°С‚РјРѕСЃС„РµСЂСѓ
                    РЅРµРґРѕСЃРєР°Р·Р°РЅРЅРѕСЃС‚Рё, С‡Р°СЃС‚Рѕ СЃ РєРёРЅРµРјР°С‚РѕРіСЂР°С„РёС‡РµСЃРєРёРј РїРѕРґС…РѕРґРѕРј Рє СЃРІРµС‚Сѓ.
                </li>
                <li>
                    <b>РљРѕРЅС†РµРїС‚СѓР°Р»СЊРЅРѕСЃС‚СЊ</b> вЂ“ РєР°Р¶РґС‹Р№ РїСЂРѕРµРєС‚ РєР°Рє С†РµР»РѕСЃС‚РЅС‹Р№ РЅР°СЂСЂР°С‚РёРІ, РІ РєРѕС‚РѕСЂРѕРј РёРЅС‚РµСЂСЊРµСЂ вЂ” СЌС‚Рѕ РЅРµ РїСЂРѕСЃС‚Рѕ
                    СЃСЂРµРґР°, Р° РѕСЃРјС‹СЃР»РµРЅРЅР°СЏ С…СѓРґРѕР¶РµСЃС‚РІРµРЅРЅР°СЏ СЃРёСЃС‚РµРјР°.
                </li>
                <li>
                    <b>РњРѕРЅРѕС…СЂРѕРјРЅС‹Рµ РёР»Рё СЃРґРµСЂР¶Р°РЅРЅС‹Рµ РїР°Р»РёС‚СЂС‹</b> вЂ“ С†РІРµС‚ С‡Р°С‰Рµ РІРѕСЃРїСЂРёРЅРёРјР°РµС‚СЃСЏ РєР°Рє С‡Р°СЃС‚СЊ РјР°С‚РµСЂРёР°Р»СЊРЅРѕР№
                    СЃС‚СЂСѓРєС‚СѓСЂС‹, Р° РЅРµ РїСЂРѕСЃС‚Рѕ РґРµРєРѕСЂР°С‚РёРІРЅС‹Р№ СЃР»РѕР№.
                </li>
                <p>
                    РњРѕРё РєР»РёРµРЅС‚С‹ вЂ” СЌС‚Рѕ С‚Рµ, РєС‚Рѕ РЅРµ РїСЂРѕСЃС‚Рѕ Р·Р°РєР°Р·С‹РІР°РµС‚ РёРЅС‚РµСЂСЊРµСЂ, Р° РІРѕРІР»РµРєР°РµС‚СЃСЏ РІ РїСЂРѕС†РµСЃСЃ РµРіРѕ СЃРѕР·РґР°РЅРёСЏ РєР°Рє РІ
                    С‚РІРѕСЂС‡РµСЃРєСѓСЋ РєРѕР»Р»Р°Р±РѕСЂР°С†РёСЋ, РіРґРµ РїСЂРѕСЃС‚СЂР°РЅСЃС‚РІРѕ СЃС‚Р°РЅРѕРІРёС‚СЃСЏ РЅРµ В«РґРµРєРѕСЂР°С†РёРµР№В», Р° СЃРјС‹СЃР»РѕРІРѕР№ РѕР±РѕР»РѕС‡РєРѕР№ РёС…
                    Р»РёС‡РЅРѕСЃС‚Рё РёР»Рё РёРґРµРё; вЂ” СЌС‚Рѕ Р»СЋРґРё Рё Р±СЂРµРЅРґС‹, РєРѕС‚РѕСЂС‹Рµ РІРѕСЃРїСЂРёРЅРёРјР°СЋС‚ РїСЂРѕСЃС‚СЂР°РЅСЃС‚РІРѕ РєР°Рє РёРЅС‚РµР»Р»РµРєС‚СѓР°Р»СЊРЅСѓСЋ,
                    СЌРјРѕС†РёРѕРЅР°Р»СЊРЅСѓСЋ Рё РІРґРѕС…РЅРѕРІР»СЏСЋС‰СѓСЋ СЃСЂРµРґСѓ, Р° РЅРµ РїСЂРѕСЃС‚Рѕ РєР°Рє С„СѓРЅРєС†РёРѕРЅР°Р»СЊРЅС‹Р№ РёРЅС‚РµСЂСЊРµСЂ.{" "}
                </p>
            </div>
            <Divider className={styles.divider} />
            <div className={styles.projects}>{projects}</div>
        </div>
    );
};

import preview_1 from "./images/1 Villa Tertullian/204b.jpg";
import image_1_1 from "./images/1 Villa Tertullian/205b.jpg";
import image_1_2 from "./images/1 Villa Tertullian/206b.jpg";
import image_1_3 from "./images/1 Villa Tertullian/207b.jpg";
import image_1_4 from "./images/1 Villa Tertullian/208b.jpg";
import image_1_5 from "./images/1 Villa Tertullian/213b.jpg";
import backInfo_1 from "./images/1 Villa Tertullian/b1.jpg";
import image_2_1 from "./images/2 Villa Pieter Bruegel/192b.jpg";
import image_2_2 from "./images/2 Villa Pieter Bruegel/194b.jpg";
import preview_2 from "./images/2 Villa Pieter Bruegel/195b.jpg";
import image_2_3 from "./images/2 Villa Pieter Bruegel/196b.jpg";
import image_2_4 from "./images/2 Villa Pieter Bruegel/198b.jpg";

import backInfo_2 from "./images/2 Villa Pieter Bruegel/b23.jpg";
import preview_3 from "./images/3 Apartment Koloman Mozer/209b.jpg";
import image_3_1 from "./images/3 Apartment Koloman Mozer/210b.jpg";
import image_3_2 from "./images/3 Apartment Koloman Mozer/211b.jpg";
import image_3_3 from "./images/3 Apartment Koloman Mozer/212b.jpg";
import image_3_4 from "./images/3 Apartment Koloman Mozer/221.png";

import backInfo_3 from "./images/3 Apartment Koloman Mozer/b32.jpg";
import preview_4 from "./images/4 Apartment Robert Campin/199b.jpg";
import image_4_1 from "./images/4 Apartment Robert Campin/200b.jpg";
import image_4_2 from "./images/4 Apartment Robert Campin/201b.jpg";
import image_4_3 from "./images/4 Apartment Robert Campin/202b.jpg";
import image_4_4 from "./images/4 Apartment Robert Campin/219.png";

import backInfo_4 from "./images/4 Apartment Robert Campin/b4.jpeg";
import preview_5 from "./images/5 Villa In Pineda/182b.jpg";
import image_5_1 from "./images/5 Villa In Pineda/183b.jpg";
import image_5_2 from "./images/5 Villa In Pineda/184b.jpg";
import image_5_3 from "./images/5 Villa In Pineda/185b.jpg";
import image_5_4 from "./images/5 Villa In Pineda/186b.jpg";
import image_5_5 from "./images/5 Villa In Pineda/187b.jpg";

import backInfo_5 from "./images/5 Villa In Pineda/b5.jpeg";
import preview_6 from "./images/6 villa Zeus/214b.jpg";
import image_6_1 from "./images/6 villa Zeus/215b.jpg";
import image_6_2 from "./images/6 villa Zeus/216b.jpg";
import image_6_3 from "./images/6 villa Zeus/217b.jpg";
import image_6_4 from "./images/6 villa Zeus/218b.jpg";

import backInfo_6 from "./images/6 villa Zeus/b6.jpeg";
import preview_7 from "./images/7 Restoration of Prokofiev's villa/153.jpg";
import image_7_1 from "./images/7 Restoration of Prokofiev's villa/154.jpg";
import image_7_2 from "./images/7 Restoration of Prokofiev's villa/155.jpg";
import image_7_3 from "./images/7 Restoration of Prokofiev's villa/156.jpg";
import image_7_4 from "./images/7 Restoration of Prokofiev's villa/157.jpg";
import image_7_5 from "./images/7 Restoration of Prokofiev's villa/158.jpg";
import image_7_6 from "./images/7 Restoration of Prokofiev's villa/159.jpg";

import backInfo_7 from "./images/7 Restoration of Prokofiev's villa/b7.jpeg";

export const projectsData = [
    {
        id: "1",
        label: "Villa Tertullian",
        preview: preview_1,
        backInfo: backInfo_1,
        images: [preview_1, image_1_2, image_1_1, image_1_4, image_1_3, image_1_5],
    },
    {
        id: "2",
        label: "Villa Bruegel",
        preview: preview_2,
        backInfo: backInfo_2,
        images: [preview_2, image_2_2, image_2_1, image_2_3, image_2_4],
    },
    {
        id: "3",
        label: "Apartment Wien",
        preview: preview_3,
        backInfo: backInfo_3,
        images: [preview_3, image_3_1, image_3_2, image_3_3, image_3_4],
    },
    {
        id: "6",
        label: "Villa Zeus",
        preview: preview_6,
        backInfo: backInfo_6,
        images: [preview_6, image_6_1, image_6_2, image_6_3, image_6_4],
        pano2vr: [
            {
                link: "./pano/6_1/index.html",
                title: "Panorama 360",
            },
        ],
    },
    {
        id: "7",
        label: "Restoration of Prokofiev's villa",
        preview: preview_7,
        backInfo: backInfo_7,
        images: [preview_7, image_7_1, image_7_2, image_7_3, image_7_4, image_7_5, image_7_6],
    },
    {
        id: "4",
        label: "Apartment Campin",
        preview: preview_4,
        backInfo: backInfo_4,
        images: [preview_4, image_4_1, image_4_2, image_4_3, image_4_4],
        videos: [
            {
                link: "./video/Photo-1-500012-0-Animated.mp4",
                type: "video/mp4",
                height: 320,
                width: 320,
            },
        ],
    },
    {
        id: "5",
        label: "Villa en Pineda",
        preview: preview_5,
        backInfo: backInfo_5,
        images: [preview_5, image_5_1, image_5_2, image_5_3, image_5_4, image_5_5],
    },
    {
        id: "other",
        label: "",
        preview: "",
        backInfo: "",
        images: [
            "1036.jpg",
            "1038.jpg",
            "1037.jpg",
            "1039.jpg",
            "1042.jpg",
            "428.jpg",
            "427.jpg",
            "426.jpg",
            "401.jpg",
            "425.jpg",
            "423.jpg",
            "1052b.jpg",
            // "1053b.jpg",
            "1050b.jpg",
            "1051b.jpg",
            "1049b.jpg",
            "1054b.jpg",
            "1055b.jpg",
            "1056b.jpg",
            "422.jpg",
            "397.jpg",
            "398.jpg",
            "421.jpg",
            "420.jpg",
            "419.jpg",
            "418.jpg",
            "412.jpg",
            "411.jpg",
            "405.jpg",
            "404.jpg",
            "424.jpg",
            "403.jpg",
            "402.jpg",
            "391.jpg",
            "390.jpg",
            "392.jpg",
        ],
    },
];
